import { useState, useMemo } from 'react'

import {
	useReactTable,
	ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getPaginationRowModel,
	getSortedRowModel,
	flexRender,
} from '@tanstack/react-table'

import { columns } from '../utils/columns'
import { Filter, fuzzyFilter, exactFilter, DebouncedInput } from '../utils/filters'

const ExpTable = (props:any) => {

	const data = useMemo(() => props.tableData, [props.tableData])
	// console.log(data)
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
		[]
	)
	const [globalFilter, setGlobalFilter] = useState('')

	const table = useReactTable({
		data,
		columns,
		filterFns: {
			fuzzy: fuzzyFilter,
		},
		state: {
			columnFilters,
			globalFilter,
		},
		initialState: {
			pagination: {
				pageSize: 20
			}
		},
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
    	globalFilterFn: exactFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	})

	return (
		<>
			<div className='flex justify-center mt-10'>
				<DebouncedInput
					value={globalFilter ?? ''}
					onChange={(value:any) => setGlobalFilter(String(value))}
					className='p-2 font-lg shadow-md rounded-md w-1/4'
					placeholder='Search all columns...'
				/>
      		</div>
			<div className='text-bsk_dark_blue my-2 mx-auto text-lg flex justify-center'>
				<div>{table.getPrePaginationRowModel().rows.length} Experiments</div>
			</div>
			<div className='px-2 pt-6 pb-16 h-full text-sm'>
				<table className='shadow-md mx-auto'>
					<thead>
						{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(header => (
							<th className={'bg-slate-200 text-bsk_dark_blue p-2 text-md' + (header.colSpan > 1 ? ' border-b border-slate-400' : '')} 
								key={header.id} colSpan={header.colSpan}>
							{header.isPlaceholder ? null : (
							<>
								<div
								{...{
									className: header.column.getCanSort()
									? 'cursor-pointer select-none'
									: '',
									onClick: header.column.getToggleSortingHandler(),
								}}
								>
								{flexRender(
									header.column.columnDef.header,
									header.getContext()
								)}
								{{
									asc: ' 🔼',
									desc: ' 🔽',
								}[header.column.getIsSorted() as string] ?? null}
								</div>
								{header.column.getCanFilter() ? (
								<div>
									<Filter column={header.column} table={table} />
								</div>
								) : null}
							</>
							)}
						</th>
							))}
						</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map(row => (
						<tr className='border-b-2 border-slate-100 bg-white hover:bg-slate-50 cursor-pointer' 
							key={row.id} 
							onClick={()=>{
								window.location.href= '/experiment/' + row.original['id']
							}}
						>
							{row.getVisibleCells().map(cell => (
							<td className='px-4 py-2 text-bsk_dark_blue text-center' key={cell.id}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
							))}
						</tr>
						))}
					</tbody>
				</table>
				<div className='flex items-center gap-2 mt-4 mx-4 text-bsk_dark_blue'>
					<button
						className='border rounded p-1'
						onClick={() => table.setPageIndex(0)}
						disabled={!table.getCanPreviousPage()}
					>
						{'<<'}
					</button>
					<button
						className='border rounded p-1'
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
					>
						{'<'}
					</button>
					<button
						className='border rounded p-1'
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
					>
						{'>'}
					</button>
					<button
						className='border rounded p-1'
						onClick={() => table.setPageIndex(table.getPageCount() - 1)}
						disabled={!table.getCanNextPage()}
					>
						{'>>'}
					</button>
					<span className='flex items-center gap-1'>
					<div>Page</div>
					<strong>
						{table.getState().pagination.pageIndex + 1} of{' '}
						{table.getPageCount()}
					</strong>
					</span>
					<span className='flex items-center gap-1'>
					| Go to page:
					<input
						type='number'
						defaultValue={table.getState().pagination.pageIndex + 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							table.setPageIndex(page)
						}}
						className='border p-1 rounded w-16'
					/>
					</span>
					<select
						value={table.getState().pagination.pageSize}
						onChange={e => {
							table.setPageSize(Number(e.target.value))
						}}
					>
						{[10, 20, 30, 50, 100].map(pageSize => (
							<option key={pageSize} value={pageSize}>
							Show {pageSize}
							</option>
						))}
					</select>
				</div>
			</div>
		</>
	)
}

export default ExpTable
