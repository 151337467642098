function ResultsObservations(props:any) {
	
	return (
		<div className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-6'>
			<div className='overflow-hidden shadow-md rounded-lg'>
				{/* header */}
				<div className='px-6 py-4 bg-white border-b border-gray-200 font-bold flex'>
					<div className='inline-block grow text-bsk_darket_blue text-center'>Results and observations</div>
				</div>
				{/* body */}
				<div className='p-6 bg-white border-b border-gray-200'>
					<div className='overflow-x-auto'>
						<div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue w-1/6'>Finding</label>
								<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-5/6'>{props.finding}</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500  w-1/6'>Present</label>
								<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-5/6'>{props.finding_present}</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/6'>Notes</label>
								<div className='inline-block mx-2 my-1 pr-16 text-md text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-5/6'>{props.notes}</div>
							</div>
							{ 
								props.caution === undefined ? null :
								<div className='my-1 flex'>
									<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/6'>Caution</label>
									<div className='inline-block mx-2 my-1 pr-16 text-md text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-5/6'>{props.caution}</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ResultsObservations