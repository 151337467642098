import {createColumnHelper } from '@tanstack/react-table'

export type Experiment = {
	id: string,
	exp_method_name: string,
	main_component: string,
	main_component_modification: string,
	secondary_components: string,
	other_modifications: string,
	finding: string,
	finding_present: number,
	host_system: string,
	pubmed_id: string
}

const columnHelper = createColumnHelper<Experiment>()

export const columns = [
	columnHelper.accessor('exp_method_name', {
		cell: info => info.getValue(),
		header: () => <span>Experimental method</span>,
	}),
	columnHelper.group({
		header: 'Experimental component 1',
		columns: [
			columnHelper.accessor('main_component', {
				cell: info => info.getValue(),
				header: () => <span>Name</span>,
			}),
			columnHelper.accessor((row:any) => row.main_component_modification + ';' + row.main_component_mod_effect, {
				id: 'mod_effect',
				cell: info => 
					<span>
						{info.getValue().split(';')[0]}
						{
							info.getValue().split(';')[1] === 'increasing' ? 
							<div className='text-green-700 inline-block mx-2'>&#9650;</div> : 
							info.getValue().split(';')[1] === 'combined: increasing' ? 
							<div className='text-green-700 inline-block mx-2'>&#9650;(c)</div> : 
							info.getValue().split(';')[1] === 'gain of function' ? 
							<div className='text-green-700 inline-block mx-2'>&#8853;</div> : 
							info.getValue().split(';')[1] === 'combined: gain of function' ? 
							<div className='text-green-700 inline-block mx-2'>&#8853;(c)</div> : 
							info.getValue().split(';')[1] === 'decreasing' ?
							<div className='text-red-700 inline-block mx-2'>&#9660;</div> :
							info.getValue().split(';')[1] === 'combined: decreasing' ?
							<div className='text-red-700 inline-block mx-2'>&#9660; (c)</div> :
							info.getValue().split(';')[1] === 'loss of function' ?
							<div className='text-red-700 inline-block mx-2'>&#8854;</div> :
							info.getValue().split(';')[1] === 'combined: loss of function' ?
							<div className='text-red-700 inline-block mx-2'>&#8854; (c)</div> :
							info.getValue().split(';')[1] === 'no effect' ?
							<div className='text-slate-700 inline-block mx-2'>&#9671;</div> :
							''
						}
					</span>,
				header: () => <span>Modification</span>,
			})
		]
	}),
	columnHelper.group({
		header: 'Other Experimental components',
		columns: [
			columnHelper.accessor('secondary_components', {
				cell: info => info.getValue(),
				header: () => <span>Name</span>,
			}),
			columnHelper.accessor('other_modifications', {
				cell: info => info.getValue(),
				header: () => <span>Modification</span>,
			})
		]
	}),
	columnHelper.accessor(row => `${row.finding_present === 1 ? '(+)' : row.finding_present === -1 ? '' : row.finding_present === 0 ? '(-)' : '' } ${row.finding}`, {
		id: 'finding and present',
		cell: info => info.getValue(),
		header: () => <span>Finding</span>,
	}),
	//   columnHelper.accessor('finding_present', {
	//     header: () => 'Finding present',
	//     cell: info => info.renderValue(),
	//   }),
	columnHelper.accessor('host_system', {
		header: () => <span>Host system</span>,
	}),
	columnHelper.accessor('pubmed_id', {
		header: 'PubMed ID',
	}),
]