import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Navbar } from '@mb-group/reactcomponents'
import { BreadCrumbsNav } from '@mb-group/reactcomponents'
import ExperimentDetails from '../components/ExperimentDetails'
import Components from '../components/Components'
import ResultsObservations from '../components/Results'
import PublicationDetails from '../components/PublicationDetails'

function ExperimentPage() {

	const { experiment_index } = useParams()
	const crumbs_nav = [{title:'Home', link:'/'}, {title:'Experiment details', link: '/experiment/' + experiment_index, active:true}]

	const [experiment, setExperiment]:[any, any] = useState([])

	useEffect(()=>{
		fetch(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD : process.env.REACT_APP_API_DEV}api/experiment/?id=${experiment_index}&format=json`)
			.then((response) => response.json())
			.then(setExperiment)
		}, [experiment_index])

	// console.log(experiment)

	return (
		<div className='bg-sky-50 h-full'>
			<Navbar 
				appName={'Kinase PubParser'}
				appNameLink = {'/'}
				showAppLogo = {true}
				appLogoPath = {'./BlueSky_Kinase_logo.png'}
				appLogoLink = {'/'}
				showBurgerButton = {true}
				burgerImagePath = {'./burger-button.png'}
				burgerButtonLink ={'/'}
			/>
			<div className='items-center flex justify-between w-full pt-28'>
				<BreadCrumbsNav crumbsList={crumbs_nav}/>
			</div>
			<div className='text-sm'>
				{/* Experimental details */}
				{
					experiment.length ? 
					<ExperimentDetails 
						exp_method_name = {experiment[0].exp_method.exp_method_name}
						evidence_type = {experiment[0].exp_method.evidence_type === 1 ? 'Direct' : 'Indirect'}
						host_system = {experiment[0].exp_method.host_system}
						eco_accession = {experiment[0].exp_method.eco_accession}
						exp_coverage = {experiment[0].exp_coverage === 1 ? 'Full' : 'Partial'}
						exp_depth = {experiment[0].exp_depth === 1 ? 'Deep' : 'Shallow'}
						figure_or_table_ref = {experiment[0].figure_or_table_ref === '-' ? undefined : experiment[0].figure_or_table_ref}
						exp_parameters = {experiment[0].exp_parameters && experiment[0].exp_parameters.length ? 
							experiment[0].exp_parameters.map((p:any)=>p.parameter_name + ' = ' + p.parameter_value).join(',') : 
							undefined 
						}
					/> :
					null
				}
				{/* Components details */}
				{
					experiment.length && experiment[0].components.length ? 
					<Components 
						main_component = {experiment[0].components.find((comp:any)=>comp.main_component === true)}
						other_components = {experiment[0].components.filter((comp:any)=>comp.main_component !== true)}
					/> :
					null
				}
				{/* Results details */}
				{
					experiment.length ? 
					<ResultsObservations 
						finding = {experiment[0].type_of_finding}
						finding_present = {experiment[0].finding_present === 1 ? 'Yes' : 'No'}
						notes = {experiment[0].notes}
						caution = {experiment[0].caution === '-' ? undefined : experiment[0].caution}
					/> :
					null
				}
				{/* Publication details */}
				{
					experiment.length ? 
					<PublicationDetails 
						pubmed_id = {experiment[0].exp_method.publication.pubmed_id}
						title = {experiment[0].exp_method.publication.title}
						journal = {experiment[0].exp_method.publication.journal}
						abstract = {experiment[0].exp_method.publication.abstract}
					/> :
					null
				}
			</div>
		</div>
	)
}

export default ExperimentPage