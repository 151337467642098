function Component(props:any) {
	// console.log(props.parameters)
	return (
		<div className='rounded-lg border flex flex-col h-full'>
			{/* Basic information */}
			<div className='inline-block mx-2 my-1 text-center font-bold w-full text-bsk_darket_blue'>
				{ 
					props.parameters.gene_name && props.parameters.gene_name !== '-' ? 
					props.parameters.gene_name : 
					props.parameters.component_name
				}
			</div>
			<div className='m-1 flex'>
				<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Type</label>
				<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{props.parameters.component_type}</div>
			</div>
			<div className='m-1 flex'>
				<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Name</label>
				<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{props.parameters.component_name}</div>
			</div>
			{
				props.parameters.external_id !== '-' && props.parameters.external_id !== '' && props.parameters.external_id.includes('CHEMBL') ?
					<div className='m-1 flex'>
						<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>ChEMBL ID</label>
						<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 underline w-2/3'>
							<a href={'https://www.ebi.ac.uk/chembl/document_report_card/'+ props.parameters.external_id} target='_blank' rel='noreferrer'>{props.parameters.external_id}</a>
						</div>
					</div>:
					props.parameters.external_id !== '-' && props.parameters.external_id !== '' ?
					<div className='m-1 flex'>
						<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Uniprot ID</label>
						<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 underline w-2/3'>
							<a href={'https://www.uniprot.org/uniprotkb/'+ props.parameters.external_id +'/entry'} target='_blank' rel='noreferrer'>{props.parameters.external_id}</a>
						</div>
					</div> :
					null
			}
			{
				props.parameters.gene_name !== '-' && props.parameters.gene_name !== ''?
				<div className='m-1 flex'>
					<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Gene</label>
					<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{props.parameters.gene_name}</div>
				</div> :
				null
			}
			{
				props.parameters.organism !== '-' && props.parameters.organism !== ''?
				<div className='m-1 flex'>
					<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Organism</label>
					<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{props.parameters.organism}</div>
				</div> :
				null
			}
			{
				props.parameters.expressed_in !== '-' && props.parameters.expressed_in !== ''?
				<div className='m-1 flex'>
					<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Expressed in</label>
					<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{props.parameters.expressed_in}</div>
				</div> :
				null
			}
			{/* Features */}
			{ props.parameters.features.length ?
				<div>
					<div className='inline-block mx-2 my-1 text-center font-bold w-full text-bsk_dark_blue'>{'Feature' + (props.parameters.features.length > 1 ? 's' : '')}</div>
					{
						props.parameters.features.map((feature:any, index:number)=>
							<div key={index} className={'py-2 mx-8' + (index < props.parameters.features.length ? ' border-t' : '')}>
								{
									feature.construct_type !== '-' && feature.construct_type !== ''?
									<div className='m-1 flex'>
										<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Construct type</label>
										<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{feature.construct_type}</div>
									</div> :null
								}
								{
									feature.construct_boundry !== '-' && feature.construct_boundry !== ''?
									<div className='m-1 flex'>
										<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Boundries</label>
										<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{feature.construct_boundry}</div>
									</div> :null
								}
								{
									feature.aa_position !== '-' && feature.aa_position !== ''?
									<div className='m-1 flex'>
										<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Position (aa)</label>
										<div className='inline-block mx-2 text-md text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-2/3'>{feature.aa_position}</div>
									</div> :null
								}
								{
									feature.modification_type !== '-' && feature.modification_type !== ''?
									<div className='m-1 flex'>
										<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Modification type</label>
										<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{feature.modification_type}</div>
									</div> :null
								}
								{
									feature.effect_of_modification !== '-' && feature.effect_of_modification !== ''?
									<div className='m-1 flex'>
										<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Effect</label>
										<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{feature.effect_of_modification}</div>
									</div> :null
								}
								{
									feature.fold_change_diff !== '-' && feature.fold_change_diff !== ''?
									<div className='m-1 flex'>
										<label className='inline-block mx-2 text-md text-right font-bold text-bsk_dark_blue w-1/3'>Fold change</label>
										<div className='inline-block mx-2 text-md text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-2/3'>{feature.fold_change_diff}</div>
									</div> :null
								}
							</div>
						)
					}
				</div>
				: null
			}
		</div>
	)
}

export default Component