import { useState, useEffect } from 'react'
import { Navbar } from '@mb-group/reactcomponents'
import { BreadCrumbsNav } from '@mb-group/reactcomponents'
import ExpTable from '../components/Table'
// import SubfamilyCard from '../components/SubfamilyCard'

const Home = () => {

	const [expData, setExpData] = useState([])
	const [tableData, setTableData] = useState([])
	const crumbs_nav = [{title:'Home', link:'/', active:true}]

	useEffect(()=>{
		fetch(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD : process.env.REACT_APP_API_DEV}api/experiments/?format=json`)
			.then((response) => response.json())
			.then(setExpData)
		}, [])

	// console.log(expData)

	useEffect(()=>{
		let temp_data:any = []
		if(expData.length){
			expData.forEach((exp:any)=>{

				const main_component_modification = exp.components.find((c:any)=>c.main_component === true).features.length ? 
					exp.components.find((c:any)=>c.main_component === true).features
						.filter((f:any)=> !f.modification_type.includes('tag'))
						.map((f:any)=> f.aa_position.split(':p.')[1]).join(', ') : '-'

				const main_component_mod_effect = exp.components.find((c:any)=>c.main_component === true).features.length 
					? exp.components.find((c:any)=>c.main_component === true)
						.features.filter((f:any)=> !f.modification_type.includes('tag'))[0].effect_of_modification 
					: '_'
				
				const other_components = /,(.+)/.exec(exp.list_of_participants) !== null ?
					/,(.+)/.exec(exp.list_of_participants)![1].replaceAll(',',', ') : '-'
				const other_mods_temp = exp.components.filter((c:any)=>c.main_component !== true).map((comp:any)=>{
					const comp_mods = comp.features.filter((f:any)=> !f.modification_type.includes('tag')).map((f:any)=> f.aa_position.split(':')[1]).join(', ')
					return (comp_mods.length ? comp_mods : undefined) 
				})
				const other_modifications = other_mods_temp.find((m:any)=> m !== undefined) ? other_mods_temp.filter((m:any)=> m !== undefined).join('; ') : '-'

				temp_data.push({
					id: exp.id,
					exp_method_name : exp.exp_method.exp_method_name,
					finding: exp.type_of_finding,
					finding_present: exp.finding_present,
					host_system: exp.exp_method.host_system,
					pubmed_id: exp.exp_method.publication.pubmed_id,
					main_component: exp.components.find((c:any)=>c.main_component === true).gene_name,
					main_component_modification: main_component_modification,
					main_component_mod_effect: main_component_mod_effect,
					secondary_components: other_components,
					other_modifications: other_modifications
				})
			})
			setTableData(temp_data)
		}
	},[expData])

	// console.log(tableData)
	return(
		<div className='bg-sky-50 h-full'>
			<Navbar 
				appName={'Kinase PubParser'}
				appNameLink = {'/'}
				showAppLogo = {true}
				appLogoPath = {'./BlueSky_Kinase_logo.png'}
				appLogoLink = {'/'}
				showBurgerButton = {true}
				burgerImagePath = {'./burger-button.png'}
				burgerButtonLink ={'/'}
			/>
			<div className='items-center flex justify-between w-full pt-28'>
				<BreadCrumbsNav crumbsList={crumbs_nav}/>
			</div>
			{/* <h1 className='text-3xl font-normal p-10 pt-18 mx-auto text-center text-bsk_dark_blue'>Experiments</h1> */}
			{
				tableData.length ? 
					<ExpTable tableData={tableData}/> :
					<></>
			}
		</div>
	)
}
  
export default Home