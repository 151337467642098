import { useState, useEffect, useMemo } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import {
	Column,
	Table,
	FilterFn,
} from '@tanstack/react-table'

import {
	rankItem,
  } from '@tanstack/match-sorter-utils'

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value)
	// Store the itemRank info
	addMeta({
		itemRank,
	})

	// Return if the item should be filtered in/out
	return itemRank.passed
}

export const exactFilter: FilterFn<any> = (row, columnId, value) => {
	// get the cell value
	const cell_value:any = row.getValue(columnId)
	const has_value = cell_value.toLowerCase().includes(value.toLowerCase())
	// return if value matches cell value
	return has_value
}

export function Filter({
	column,
	table,
  }: {
	column: Column<any, unknown>
	table: Table<any>
  }) {
	const firstValue = table
		.getPreFilteredRowModel()
		.flatRows[0]?.getValue(column.id)
  
	const columnFilterValue = column.getFilterValue()
	const columnUniqValues = column.getFacetedUniqueValues()
  
	const sortedUniqueValues = useMemo(
		() =>
			typeof firstValue === 'number'
		  	? []
		  	: Array.from(columnUniqValues.keys()).sort(),
	  	[columnUniqValues, firstValue]
	)
  
	return typeof firstValue === 'number' ? (
	  <div>
		<div className='flex space-x-2'>
		  <DebouncedInput
			type='number'
			min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
			max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
			value={(columnFilterValue as [number, number])?.[0] ?? ''}
			onChange={value =>
			  column.setFilterValue((old: [number, number]) => [value, old?.[1]])
			}
			placeholder={`Min ${
			  column.getFacetedMinMaxValues()?.[0]
				? `(${column.getFacetedMinMaxValues()?.[0]})`
				: ''
			}`}
			className='w-24 border shadow rounded'
		  />
		  <DebouncedInput
			type='number'
			min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
			max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
			value={(columnFilterValue as [number, number])?.[1] ?? ''}
			onChange={value =>
			  column.setFilterValue((old: [number, number]) => [old?.[0], value])
			}
			placeholder={`Max ${
			  column.getFacetedMinMaxValues()?.[1]
				? `(${column.getFacetedMinMaxValues()?.[1]})`
				: ''
			}`}
			className='w-24 border shadow rounded'
		  />
		</div>
		<div className='h-1' />
	  </div>
	) : (
	  <>
		<datalist id={column.id + 'list'}>
		  {sortedUniqueValues.slice(0, 5000).map((value: any) => (
			<option value={value} key={value} />
		  ))}
		</datalist>
		<div className='flex flex-row'>
			<DebouncedInput
				type='text'
				value={(columnFilterValue ?? '') as string}
				onChange={value => column.setFilterValue(value)}
				placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
				className='w-36 border shadow rounded my-2'
				list={column.id + 'list'}
			/>
			<div className='px-1 h-max cursor-pointer' 
				onClick={()=>{column.setFilterValue('')}}
			>
				{columnFilterValue ? <XMarkIcon className='h-4 w-4 mt-3'/> : ''}
			</div>
		</div>
		<div className='h-1' />
	  </>
	)
  }
  
  // A debounced input react component
  export function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
  }: {
	value: string | number
	onChange: (value: string | number) => void
	debounce?: number
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
	const [value, setValue] = useState(initialValue)
  
	useEffect(() => {
	  setValue(initialValue)
	}, [initialValue])
  
	useEffect(() => {
	  const timeout = setTimeout(() => {
		onChange(value)
	  }, debounce)
  
	  return () => clearTimeout(timeout)
	}, [value])
  
	return (
	  <input {...props} value={value} onChange={e => setValue(e.target.value)} />
	)
}