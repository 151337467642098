import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import '../src/App.css'
import ExperimentPage from './pages/ExperimentPage'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/experiment/:experiment_index' element={<ExperimentPage/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App
