function PublicationDetails(props:any) {
	
	return (
		<div className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-6 pb-20'>
			<div className='overflow-hidden shadow-md rounded-lg'>
				{/* header */}
				<div className='px-6 py-4 bg-white border-b border-gray-200 font-bold flex'>
					<div className='inline-block grow text-bsk_darket_blue text-center'>Publication Details</div>
				</div>
				{/* body */}
				<div className='p-6 bg-white border-b border-gray-200'>
					<div className='overflow-x-auto'>
						<div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue w-1/6'>PubMed ID</label>
								<div className='inline-block mx-2 my-1 text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-5/6 underline'>
									<a href={'https://pubmed.ncbi.nlm.nih.gov/'+ props.pubmed_id} target='_blank' rel='noreferrer'>{props.pubmed_id}</a>
								</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500  w-1/6'>Title</label>
								<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-5/6'>{props.title}</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500  w-1/6'>Journal</label>
								<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-5/6'>{props.journal}</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/6'>Abstract</label>
								<div className='inline-block mx-2 my-1 pr-16 text-md text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-5/6'>{props.abstract}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PublicationDetails