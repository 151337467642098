import Component from "./Component"

function Components(props:{
	main_component: any,
	other_components: any
}) {
	return (
		<div className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-6'>
			<div className='overflow-hidden shadow-md rounded-lg'>
				{/* header */}
				<div className='px-6 py-4 bg-white border-b border-gray-200 font-bold flex'>
					<div className='inline-block grow text-bsk_darket_blue text-center'>Components</div>
				</div>
				{/* body */}
				<div className='p-6 bg-white border-b border-gray-200 flex flex-wrap justify-center'>
					<div className='basis-1/2'>
						<div className='w-full h-full p-2'>
							<Component parameters={props.main_component}/>
						</div> 
					</div>
					{
						props.other_components !== undefined && props.other_components.length ?
						props.other_components.map((other_comp:any)=>
							<div key={other_comp.component_name} className='basis-1/2'>
								<div className='w-full h-full p-2'>
									<Component parameters={other_comp}/>
								</div> 
							</div>
						):
						null
					}
					
				</div>
			</div>
		</div>
	)
}

export default Components