function ExperimentDetails(props:any) {
	
	return (
		<div className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-12'>
			<div className='overflow-hidden shadow-md rounded-lg'>
				{/* header */}
				<div className='px-6 py-4 bg-white border-b border-gray-200 font-bold flex'>
					<div className='inline-block grow text-bsk_darket_blue text-center'>Experiment Details</div>
				</div>
				{/* body */}
				<div className='p-6 bg-white border-b border-gray-200'>
					<div className='overflow-x-auto flex'>
						<div className='basis-1/2'>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue w-1/2'>Experimental Method</label>
								<div className='inline-block mx-2 my-1 text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-1/2'>{props.exp_method_name}</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/2'>evidence type</label>
								<div className='inline-block mx-2 my-1 text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-1/2'>{props.evidence_type}</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/2'>Host system</label>
								<div className='inline-block mx-2 my-1 text-left font-medium capitalize text-bsk_dark_blue dark:text-gray-500 w-1/2'>{props.host_system}</div>
							</div>
							{ 
								props.figure_or_table_ref === undefined ? null :
									<div className='my-1 flex'>
										<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/2'>Figure/Table reference</label>
										<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-1/2'>{props.figure_or_table_ref}</div>
									</div>
							}
						</div>
						<div className='basis-1/2'>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/2'>ECO accession</label>
								<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 underline w-1/2'>
									<a href={'https://evidenceontology.org/browse/#'+ props.eco_accession.replaceAll(':','_')} target='_blank' rel='noreferrer'>{props.eco_accession}</a>
								</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/2'>Curation coverage</label>
								<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-1/2'>{props.exp_coverage}</div>
							</div>
							<div className='my-1 flex'>
								<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/2'>Curation depth</label>
								<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-1/2'>{props.exp_depth}</div>
							</div>
							{ 
								props.exp_parameters === undefined ? null :
									<div className='my-1 flex'>
										<label className='inline-block mx-2 my-1 text-right font-bold text-bsk_dark_blue dark:text-gray-500 w-1/2'>Experimental parameters</label>
										<div className='inline-block mx-2 my-1 text-left font-medium text-bsk_dark_blue dark:text-gray-500 w-1/2'>{props.exp_parameters}</div>
									</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ExperimentDetails